import React from 'react';
import {
  PageHero,
  GoodBad,
  GoodBadItem,
  Link,
  List,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from 'docComponents';
import pageHeroData from '../../../../data/pages/words.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="Team Details" subnav="words">
      <PageHero heroData={pageHeroData} tierTwo="Content Elements" tierThree="Team Details" />

      <Section>
        <Paragraph>
          The order (and occasional exclusion) of that information all depends on where a team is being named.
        </Paragraph>
        <Paragraph>
          The full list of possible details—and their required order—is organization + gender + level + sport. In the
          case of a high school volleyball team, it would read <em>East High School girls’ varsity* volleyball</em>.
        </Paragraph>
        <Paragraph>
          *Level isn’t required, as we don’t always get that detail from the user during sign-up. Should it be necessary
          or included, it belongs between the gender and sport.
        </Paragraph>
      </Section>

      <Section title="Organizations">
        <SectionSubhead>Names</SectionSubhead>
        <Paragraph>
          An organization is named whatever the user provides during sign-up. It may be a high school, but it could also
          be an academy, day school, institute or something else entirely. It’s important we give them the freedom to
          apply that name, not only for the subsequent team names, but for the sake of opponents trying to find them{' '}
          <em>in Hudl</em>.
        </Paragraph>
        <Paragraph>The same goes for colleges and universities. There is a difference.</Paragraph>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>Our Lady Of Mercy Academy High School</Paragraph>
            <Paragraph>Middle Central Catholic HS</Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>Our Lady of Mercy Academy</Paragraph>
            <Paragraph>Middle Central Catholic</Paragraph>
          </GoodBadItem>
        </GoodBad>
        <Paragraph>
          <List type="unordered">
            <li>
              <strong>Don't</strong> assume anything about the organization name.
            </li>
            <li>
              <strong>Do</strong> use common sense to apply the appropriate name to a profile.
            </li>
          </List>
        </Paragraph>
        <Paragraph>
          Club names could be <em>anything</em>, so it’s not as easy as assuming academy from “high school academy”.
          Take these at the user’s word.
        </Paragraph>
        <SectionSubhead>Capitalization</SectionSubhead>
        <Paragraph>Organizations are always title case. Easy as that!</Paragraph>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>EAST HIGH SCHOOL</Paragraph>
            <Paragraph>Midlands university</Paragraph>
            <Paragraph>archbishop BERGAN</Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>East High School</Paragraph>
            <Paragraph>Midlands University</Paragraph>
            <Paragraph>Archbishop Bergan</Paragraph>
          </GoodBadItem>
        </GoodBad>
        <List type="unordered">
          <li>
            <strong>Don't</strong> present them with all caps or other erratic capitalization.
          </li>
          <li>
            <strong>Do</strong> properly title case all organization names.
          </li>
        </List>
        <SectionSubhead>Appearances</SectionSubhead>
        <Paragraph>
          The organization name appears <strong>by itself</strong> in a number of spots, all where the gender and sport
          are implied. Think schedules and libraries, where the viewer knows the context of “this is men’s basketball
          we’re talking about!” Highlights are a solid public example.
        </Paragraph>
        <Paragraph>
          Listing organization, gender and sport in <em>all of those places</em> would become redundant (and cramped).
        </Paragraph>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>Lincoln High School girls' varsity basketball</Paragraph>
            <Paragraph>Millard High School girls' varsity basketball</Paragraph>
            <Paragraph>Tecumseh High School girls' varsity basketball</Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>Lincoln High School</Paragraph>
            <Paragraph>Millard High School</Paragraph>
            <Paragraph>Tecumseh High School</Paragraph>
          </GoodBadItem>
        </GoodBad>
        <List type="unordered">
          <li>
            <strong>Don't</strong> provide the full team name every time.
          </li>
          <li>
            <strong>Do</strong> list only the organization where more teams of the same gender and sport appear.
          </li>
        </List>
        <SectionSubhead>Abbreviations</SectionSubhead>
        <Paragraph>
          Never abbreviate organizations on schedules and highlights, where a more public audience may be less familiar
          with possible opponents.
        </Paragraph>
        <Paragraph>
          The most common and appropriate spot for abbreviated organizations is reports. The user creating the report is
          already familiar with the opponent, having chosen them from the schedule and/or library.
        </Paragraph>
        <Paragraph>
          Long story short, <strong>abbreviations are rarely the default</strong>. If the user chooses to abbreviate
          when naming video or adding plays to their playbook, that’s up to them.
        </Paragraph>
      </Section>
      <Section title="Teams">
        <Paragraph>
          The full team name adds gender and sport to the organization. More often than not, if you’re not listing the
          organization <em>on its own</em>, you’ll need the full team name instead.
        </Paragraph>
        <SectionSubhead>Appearances</SectionSubhead>
        <Paragraph>
          The most common instance of a full team name is the public profile. There, the audience would appreciate and
          benefit from every possible detail.
        </Paragraph>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>JHS boys' basketball</Paragraph>
            <Paragraph>JHS boys' basketball</Paragraph>
            <Paragraph>JHS boys' basketball</Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>Johnsonville High School boys' varsity basketball</Paragraph>
            <Paragraph>Jefferson High School boys' varsity basketball</Paragraph>
            <Paragraph>Jefferson High School boys' reserve basketball</Paragraph>
          </GoodBadItem>
        </GoodBad>
        <List type="unordered">
          <li>
            <strong>Don't</strong> omit details just for the sake of space.
          </li>
          <li>
            <strong>Do</strong> provide the complete team name where context may be lacking.
          </li>
        </List>
        <br />
        <Paragraph>
          <strong>The only time gender and sport can appear on their own is internally.</strong> It usually makes sense
          in the list of teams a user can access, or the individual teams on a given organization’s invoice. Both of
          those are possible because the user is 100% aware of the organization in question.
        </Paragraph>
        <Paragraph>
          The simple combo of gender + sport should never appear anywhere public, especially profiles, highlights and
          schedules.
        </Paragraph>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>2018 Girls' Lacrosse Highlights</Paragraph>
            <Paragraph>Boys' Varsity Golf Schedule</Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>Girls' Lacrosse - Gold package, unlimited Assist</Paragraph>
            <Paragraph>Boys' Varsity Golf - Silver package</Paragraph>
          </GoodBadItem>
        </GoodBad>
        <List type="unordered">
          <li>
            <strong>Don't</strong> use gender and sport alone to identify a team for the public.
          </li>
          <li>
            <strong>Do</strong> limit the name to gender and sport when organization is already clear.
          </li>
        </List>
        <SectionSubhead>Capitalization</SectionSubhead>
        <Paragraph>
          Organization is the only thing you should <em>always</em> title case in a team name. The capitalization of
          gender and sport depend on where the team name appears.
        </Paragraph>
        <Paragraph>
          They should be capitalized when they’re part of an actual title, like on public profiles, not when simply
          naming a team in a larger list or via email.
        </Paragraph>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>Inglewood Boys' Varsity Football plays away next Friday.</Paragraph>
            <Paragraph>Omaha High girls' golf tournament</Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>Inglewood boys' varsity football plays away next Friday.</Paragraph>
            <Paragraph>Omaha High Girls' Golf Tournament</Paragraph>
          </GoodBadItem>
        </GoodBad>
        <List type="unordered">
          <li>
            <strong>Don't</strong> title case gender and sport in everyday correspondence.
          </li>
          <li>
            <strong>Do</strong> title case the full team name as the title of a profile or account.
          </li>
        </List>
        <SectionSubhead>Punctuation</SectionSubhead>
        <Paragraph>
          <strong>The gender on a team is always possessive. That means an apostrophe is always necessary.</strong>
        </Paragraph>
        <List type="unordered">
          <li>For girls' and boys', simply add the apostrophe to the very end. No additional ‘s’ is needed.</li>
          <li>For men’s and women’s, the plural is formed with -en, so an ‘s’ is needed along with the apostrophe.</li>
        </List>
        <Paragraph />
        <Paragraph>
          For more about apostrophes and other punctuation across Hudl,{' '}
          <Link href="/words/writing-guidelines/punctuation">check the guidelines</Link>.
        </Paragraph>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
